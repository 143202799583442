{
  "en": {
    "Upsell Item Orders": "Upsell Item Orders",
    "Order ID": "Order ID",
    "Providers": "Providers",
    "Status": "Status",
    "Ordered Date": "Ordered Date",
    "Checkin Date": "Checkin Date",
    "Delivery date": "Delivery date",
    "Property": "Property",
    "#Items": "#Items",
    "Cancel reason": "Cancel reason",
    "Amount": "Amount",
    "new": "New",
    "ordered": "Ordered",
    "fixed": "Fixed",
    "received": "Received",
    "cancelled": "Cancelled",
    "paid": "Paid",
    "Reservation summary": "Reservation summary",
    "Reservation ID": "Reservation ID",
    "Room name": "Room name",
    "Property Name": "Property Name",
    "Package Plan Name": "Package Plan Name",
    "Check In Date": "Check In Date",
    "Package Plan Code": "Package Plan Code",
    "Check In Time": "Check In Time",
    "Number of orders": "Number of orders",
    "Check Out Date": "Check Out Date",
    "Order Information": "Order Information",
    "Payment ID": "Payment ID",
    "Payment Status": "Payment Status",
    "Captured amount": "Captured amount",
    "Cancel Order": "Cancel Order",
    "Cancelled at": "Cancelled at",
    "Cancellation reason": "Cancellation reason",
    "Cancelled by": "Cancelled by",
    "Cancellation fee from": "100% cancellation fee from {date}",
    "Cancel order successfully": "Cancel order successfully",
    "Confirmation cancel order": "Confirmation cancel order",
    "Are you sure you want to cancel this order": "Are you sure you want to cancel this order?",
    "Cancel": "Cancel",
    "OK": "OK",
    "Item": "Item",
    "Provider": "Provider",
    "Delivery time": "Delivery time",
    "Receiver": "Receiver",
    "Order before": "Order before",
    "Provider reference": "Provider reference",
    "Unit Price": "Unit Price",
    "Quantity": "Quantity",
    "Item Price": "Item Price",
    "Order Info": "Order Info",
    "Items": "Items",
    "Clear": "Clear",
    "Search Item Title": "Search Item Title",
    "Upsell Item Title": "Upsell Item Title",
    "Order/Reservation ID": "Order/Reservation ID",
    "Choose date": "Choose date",
    "Date range": "Date range",
    "Edit Order Item": "Edit Order Item",
    "Provider Preference": "Provider Preference",
    "Delivery Date": "Delivery Date",
    "Delivery Time": "Delivery Time",
    "Save": "Save",
    "Successfully update order item": "Successfully update order item",
    "Please input a receiver": "Please input a receiver!",
    "Please input a provider": "Please input a provider!",
    "Please input delivery date": "Please input delivery date!",
    "Please input delivery time": "Please input delivery time!"
  },
  "ja": {
    "Upsell Item Orders": "注文",
    "Order ID": "注文ID",
    "Providers": "提供者",
    "Ordered Date": "注文日",
    "Checkin Date": "チェックイン日",
    "Property": "施設",
    "#Items": "アイテム数",
    "Cancel reason": "キャンセル理由",
    "Amount": "金額",
    "new": "新規",
    "ordered": "注文済み",
    "fixed": "確定",
    "received": "受取済み",
    "cancelled": "キャンセル済み",
    "paid": "支払い済み",
    "Reservation summary": "予約情報",
    "Reservation ID": "予約ID",
    "Room name": "部屋",
    "Property Name": "施設",
    "Package Plan Name": "プラン",
    "Check In Date": "チェックイン",
    "Package Plan Code": "プランコード",
    "Check In Time": "チェックイン時",
    "Number of orders": "注文数",
    "Check Out Date": "チェックアウト日",
    "Order Information": "注文情報",
    "Payment ID": "決済ID",
    "Payment Status": "決済ステータス",
    "Captured amount": "決済金額",
    "Cancel Order": "キャンセルする",
    "Cancelled at": "キャンセル日",
    "Cancellation reason": "キャンセル理由",
    "Cancelled by": "キャンセル者",
    "Cancellation fee from": "{date}日からご計金額の100%キャンセル料",
    "Cancel order successfully": "注文キャンセルされました",
    "Confirmation cancel order": "注文キャンセル",
    "Are you sure you want to cancel this order": "注文キャンセルしますか?",
    "Cancel": "キャンセル",
    "OK": "OK",
    "Item": "アイテム",
    "Provider": "提供者",
    "Status": "ステータス",
    "Delivery date": "配送日",
    "Delivery time": "配送時間",
    "Receiver": "受取者",
    "Order before": "注文締め切り",
    "Provider reference": "提供者用ID",
    "Unit Price": "単価",
    "Quantity": "数",
    "Item Price": "アイテム金額",
    "Order Info": "注文情報",
    "Items": "アイテム",
    "Clear": "クリア",
    "Search Item Title": "アイテムタイトル検索",
    "Upsell Item Title": "アイテムタイトル",
    "Order/Reservation ID": "予約・注文 ID",
    "Choose date": "日付を選択",
    "Date range": "日付",
    "Edit Order Item": "アイテム変更",
    "Provider Preference": "提供者優先",
    "Delivery Date": "配送日",
    "Delivery Time": "配送時間",
    "Save": "保存",
    "Successfully update order item": "アイテム更新しました",
    "Please input a receiver": "受取者を入れてください",
    "Please input a provider": "提供者用を入れてください",
    "Please input delivery date": "配送日を入れてください",
    "Please input delivery time": "配送時間を入れてください"
  }
}