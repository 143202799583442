export const ORDER_STATUS = {
  NEW: 'new',
  FIXED: 'fixed',
  ORDERED: 'ordered',
  RECEIVED: 'received',
  CANCELLED: 'cancelled',
  PAID: 'paid',
};

export const ORDER_STATUS_OPTION = [
  { label: 'New', value: ORDER_STATUS.NEW },
  { label: 'Fixed', value: ORDER_STATUS.FIXED },
  { label: 'Ordered', value: ORDER_STATUS.ORDERED },
  { label: 'Received', value: ORDER_STATUS.RECEIVED },
  { label: 'Cancelled', value: ORDER_STATUS.CANCELLED },
  { label: 'Paid', value: ORDER_STATUS.PAID },
];
