<template>
  <div class="section">
    <div class="container">
      <div class="columns is-large-gap">
        <!-- start of left section -->
        <div class="column is-6 left-section">
          <!-- start of basic information section -->
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Reservation summary') }}
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Reservation ID') }}
                </div>
                <div>
                  <router-link
                    v-if="reservationSummary?.id"
                    :to="`/reservation/${reservationSummary?.id}/basic-info`"
                  >
                    {{ reservationSummary?.reservationNumber }}
                  </router-link>
                  <span v-else>–</span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room name') }}
                </div>
                <div>
                  {{
                    reservationSummary?.reservedRoom?.room?.name
                      || reservationSummary?.reservedRoom?.roomName
                      || '–'
                  }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Property Name') }}
                </div>
                <div>
                  {{ reservationSummary?.property?.name || '–' }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Package Plan Name') }}
                </div>
                <div>
                  {{ reservationSummary?.details?.basicInfo?.packagePlanName || '–' }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Check In Date') }}
                </div>
                <div>
                  {{ reservationSummary?.checkin || '–' }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Package Plan Code') }}
                </div>
                <div>
                  {{ reservationSummary?.details?.basicInfo?.packagePlanCode || '–' }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Check In Time') }}
                </div>
                <div>
                  {{ reservationSummary?.details?.basicInfo?.checkinTime || '–' }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Number of orders') }}
                </div>
                <div>
                  <router-link
                    v-if="reservationSummary?.id && upsellItemOrder?.numberOfOrders"
                    :to="`/upsell-item-orders/?orderId=${reservationSummary?.reservationNumber}`"
                  >
                    {{ `${upsellItemOrder?.numberOfOrders}` }}
                  </router-link>
                  <span v-else>–</span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Check Out Date') }}
                </div>
                <div>
                  {{ reservationSummary?.checkout || '–' }}
                </div>
              </div>
            </div>
          </div>
          <!-- the end of reservation information section -->
        </div>
        <!-- the start of right section -->
        <div class="column is-6 right-section">
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Order Information') }}
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Payment ID') }}
                </div>
                <div>
                  {{ upsellItemOrder?.paymentId || '–' }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Payment Status') }}
                </div>
                <div>
                  {{ upsellItemOrder?.paymentStatus || '–' }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Captured amount') }}
                </div>
                <div>
                  {{ upsellItemOrder?.currentTotal || '–' }}
                </div>
              </div>
            </div>
          </div>
          <!-- the end of order info -->
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Cancellation') }}
              </div>
            </div>
          </div>
          <!-- the start of cancel order -->
          <div class="columns is-mobile mt-2 is-align-items-center">
            <div class="column" style="padding-left: 27px">
              <div class="has-text-danger has-text-weight-bold">
                {{ $t('Cancel Order') }}
              </div>
              <div>
                {{ $t('Cancellation fee from',
                      { date: $filters.date(upsellItemOrder?.leadDate,
                                            { format: $variables.dateFormat }) })
                }}
              </div>
            </div>
            <div class="column is-narrow" style="padding-right: 27px">
              <a-button type="danger" size="large" :disabled="isCancelled || isHK" @click="$refs.confirmModal.show();">
                {{ $t('Cancel Order') }}
              </a-button>
            </div>
          </div>
          <!-- the end of cancel order -->
          <hr>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Cancelled at') }}
                </div>
                <div>
                  {{
                    $filters.date(
                      upsellItemOrder?.cancelledAt,
                      { format: $variables.dateFormat }
                    )
                  }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Cancellation reason') }}
                </div>
                <div>
                  {{ $filters.capitalizeFirst(upsellItemOrder?.cancellationReason) || '–' }}
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Cancelled by') }}
                </div>
                <div>
                  {{ upsellItemOrder?.cancelledBy || '–' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- the end of right section -->
        <Modal ref="confirmModal">
          <template #default="{ hide }">
            <h3 class="has-text-primary has-text-weight-bold is-size-4 popup-title">
              <i class="el-icon-warning has-text-danger" />
              {{ $t('Confirmation cancel order') }}
            </h3>
            <div class="column is-12">
              {{ $t('Are you sure you want to cancel this order') }}
            </div>
            <div class="column has-text-right is-12">
              <a-button
                class="m-r-2x" style="min-width: 125px"
                :disabled="loading"
                @click="hide"
              >
                {{ $t('Cancel') }}
              </a-button>
              <a-button
                type="danger"
                style="min-width: 125px"
                :loading="loading"
                @click="handleCancelOrder"
              >
                {{ $t('OK') }}
              </a-button>
            </div>
          </template>
        </Modal>
      </div>
    </div>
  </div>
</template>

<i18n src="@/locales/components/upsell-item-orders.json"></i18n>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import { ROLES } from '@/config/roles';
import checkRole from '@/utils/role';
import { ORDER_STATUS } from '@/config/upsell-item-orders';

export default {
  name: 'OrderInfo',
  components: { Modal },
  data() {
    return {
      isHK: checkRole([ROLES.HOUSEKEEPER]),
    };
  },
  computed: {
    ...mapState('upsell-item-orders', ['upsellItemOrder', 'loading']),
    reservationSummary() {
      return this.upsellItemOrder?.reservation || {};
    },
    isCancelled() {
      return this.upsellItemOrder?.status === ORDER_STATUS.CANCELLED;
    },
  },
  methods: {
    asyncData() {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER');
        this.$store.dispatch('upsell-item-orders/get', { id: this.$route.params.id });
      } catch (e) {
        console.log(e);
      }
      this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
    handleCancelOrder() {
      this.$store.dispatch('upsell-item-orders/cancel', { id: this.upsellItemOrder.id })
        .then(() => {
          this.$message.success(this.$t('Cancel order successfully'));
          this.asyncData();
        });
    },
  },
};
</script>

<style scoped lang="scss">
  .column.left-section {
    padding-right: 10px;
  }

  .column.right-section {
    padding-left: 10px;
  }

  .columns.is-large-gap {
    margin-right: -24px;

    hr {
      margin: 0 15px;
    }
  }
</style>
